@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --navbg-contact-color: #00649E; 
  --navbar-secondary-color: #33B4FF;
  --body-dark-800: #01142B;
  --navbg-navlist-color: #FFFFFF;
  --footer-color: #002033;
  --step-number: #78828E;
  --thinkcard-color: #01142B;
  --bs-cardbg-darkcolor: #01142b;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.rotate-card-inner {
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.rotate-card:hover .rotate-card-inner {
  transform: rotateY(180deg);
}

.rotate-card-front,
.rotate-card-back {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.rotate-card-back {
  transform: rotateY(180deg);
}

.clip-path-polygon {
  clip-path: polygon(30% 0, 100% 0%, 100% 100%, 0% 100%);
}

.brightnessFilter {
  -webkit-filter: invert(1) brightness(1.5)
}

.hkvegra {
  background-color: (266deg, #B4D5FF 0%, #FFE7C7 32%, #FFC7DC 69%, #B4D5FF 100%)
}

/* text animation from right to left */
.text-container {
  overflow: hidden;
  white-space: nowrap;
  border-left: none;
  border-right: none;
}

.shadow-custom {
  box-shadow: 0px 2px 56px #00000012;
}

.animated-text {
  display: inline-block;
  animation: slideRightToLeft 4s linear infinite;
}

/* Dashboard Background */
.phedo::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: transparent linear-gradient(270deg, #066EAA00 0%, #002033B1 52%, var(--c_dark) 100%) 0% 0% no-repeat padding-box;
  opacity: 0.84;
}

/* Animation Boutnce */
@keyframes custom-bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(20);
  }

  40% {
    transform: translateY(-21px);
    /* Adjust the height here */
  }

  60% {
    transform: translateY(-10px);
    /* Adjust the height here */
  }
}

.animate-custom-bounce {
  animation: custom-bounce 3s ease-in-out infinite;
}

.transition-transform {
  transition: transform 1s ease-in-out;
}

@keyframes slideRightToLeft {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}

/* HOVER BUTTON COLORS CHANGE */
.btn {
  font-size: 20px;
  font-weight: 550;
  text-decoration: none;
  overflow: hidden;
  padding: 10px 13px;
  color: var(--c_white);
  outline: none;
  border: none;
  transform: translate3d(0, 0, 0);
}

.btn:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  background: var(--c_white);
  pointer-events: none;
  transform: translate3d(0, 100%, 0);
  transition: all 0.4s;
}

.btn:hover {
  color: var(--c_primary);
  outline: none;
  border: none;
}

.btn:hover:after {
  transform: translate3d(0, 0, 0);
}

@media only screen and (max-width: 600px){
  .btn{
    font-size: 15px;
  }
}

/* Card is flip using App Development */
.flip-card {
  background-color: transparent;
  perspective: 1000px;
}

/* This container is needed to position the front and back side */
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

/* Style the front side (fallback if image is missing) */
.flip-card-front {
  color: black;
}

/* Style the back side */
.flip-card-back {
  background-color: transparent;
  color: white;
  transform: rotateY(180deg);
}
/* Desicated Team hover cards */
.zoom-in {
  overflow: hidden;
}

.zoom-in::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: inherit;
  background-size: cover;
  transform-origin: center;
  transition: transform .4s ease-in-out;
}

.zoom-in:hover::after {
  transform: scale(1.05);
}

.zoom-in:focus::after {
  transform: scale(1.05);
}

.navIcon{
  transform: rotate(180deg);
}



/* Recent Guide footer */
@keyframes bounce-horizontal {
  0%, 100% {
      transform: translateX(0);
  }
  50% {
      transform: translateX(20px);
  }
}

.animate-bounce-horizontal {
  animation: bounce-horizontal 1s infinite;
}

.custom-transition{
  transition: all 0.3s ease;
}

/* Custom scrollbar styling */
.custom-scrollbar::-webkit-scrollbar {
  width: 0px; /* Width of the entire scrollbar */
}

