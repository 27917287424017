.swiper {
  width: 100%;
  height: 100%;
  padding-bottom: 10%;
}

.swiper-slide {
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  /* margin-bottom: 100px; */
  display: block;
  width: auto;
  height: auto;
  object-fit: center;
}

/* AR VR */
.swiper-pagination-bullets {

  .swiper-pagination-bullet {
    width: 15px;
    height: 15px;
  }

  /* Active pagination color */
  .swiper-pagination-bullet-active {
    background: transparent linear-gradient(122deg, #bb3bdb 0%, #d545a4 100%) 0% 0% !important;
  }

  /* Inactive pagination color */
  .swiper-pagination-bullet {
    background-color: #fff !important;
    /* opacity: .6; */
  }
}

.swiper-button-next {
  position: absolute;
  bottom: 0px;
  left: 53%;
  top: 85%;
  transform: translateX(-50%);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  background-color: transparent;
  border-radius: 50%;
  padding: 2px;
  height: 55px;
  width: 55px;
  z-index: 999 !important;
  border: 1px solid black;
}

.swiper-wrapper {
  position: relative !important;
}

.swiper-button-prev {
  position: absolute;
  bottom: 30px;
  left: 45%;
  top: 85%;
  transform: translateX(-50%);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  background-color: transparent;
  border-radius: 50%;
  padding: 2px;
  height: 55px;
  width: 55px;
  z-index: 999 !important;
  border: 1px solid black;
}

.swiper-button-prev:hover {
  background-image: linear-gradient(to bottom, #33B4FF 0%, #00649E 100%);
  color: white;
  border: none;
}

.swiper-button-next:hover {
  background-image: linear-gradient(to bottom, #33B4FF 0%, #00649E 100%);
  color: white;
  border: none;
}

.swiper-button-prev::after {
  content: '\e5c4';
  font-family: 'Material Icons';
  font-size: x-large;
  font-weight: bolder;
}

.swiper-button-next::after {
  content: '\e5c8';
  font-family: 'Material Icons';
  font-size: x-large;
  font-weight: bolder;
}

@media screen and (min-width: 320px) and (max-width: 760px) {
  .swiper-button-next {
    left: 60%;
    top: 85%;
    transform: translateX(-50%);
    padding: 2px;
    height: 45px;
    width: 45px;
  }

  .swiper-button-prev {
  left: 40%;
  top: 85%;
  transform: translateX(-50%);
  padding: 2px;
  height: 45px;
    width: 45px;
  }
  .swiper{
    padding-bottom: 25%;
  }
}